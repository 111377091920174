@tailwind base;
@tailwind components;
@tailwind utilities;

/* styles.css (or your CSS file) */
.active-filter {
  background-color: #6b46c1; /* Set to bg-purple-600 */
  color: #ffffff; /* Set to text-white */
  padding: 0.5rem 1rem;
}
